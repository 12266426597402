<template>
  <div class="content-search-user-profile">
    <div class="content-text-title">
      <p class="text-title">Perfil de usuario</p>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  beforeMount() {
    this.$store.commit("setTabPosition", { sTabPosition: 0 });
    this.$store.commit("setTabPositionOrderPayable", 0)

  },
  methods: {

  },
};
</script>
<style scoped>
.content-search-user-profile {
  display: block;
  align-content: center;
  width: 100%;
}

.text-title {
  color: var(--primary-color-text);
  font-family: "pop-SemiBold";
  font-size: 2rem;
  margin-bottom: 0px;
}

/* XS */
@media (max-width: 599px) {
  .demoColorResponsive {
    background-color: rgb(190, 136, 226);
  }

  .content-search-administrator {
    display: block;
  }

  .content-filter-add-administrator {
    display: block;
  }

  .content-btn-add-administrator {
    margin-left: 0px;
  }

  .content-filters {
    display: block;
  }

  .content-filter-text-field {
    width: 100%;
  }
}

/* SM */
@media (min-width: 600px) and (max-width: 959px) {}

/* MD */
@media (min-width: 960px) and (max-width: 1264px) {
  .demoColorResponsive {
    background-color: rgb(201, 122, 83);
  }
}

/* LG */
@media (min-width: 1264px) and (max-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(204, 198, 109);
  }
}

/* XL */
@media (min-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(113, 199, 201);
  }
}
</style>